import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../index.css";
import "./bods-tracker.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../../components/nav"

import imgTick from "../../images/gfx/tick.png"
import imgHero from "../../images/gfx/hero-bus-open-data-package.jpg"
import imgMobileHero from "../../images/gfx/mobile-hero-bus-open-data-package.jpg"
import Contact from "../../components/contact";
import Footer from "../../components/footer"

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'track',
      activeFaq: null
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: this.state.activeTab === tab ? null : tab
    });
  }

  showFaq(tab) {
    this.setState({
      activeFaq: this.state.activeFaq === tab ? null : tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="BODS AVL - Low-cost Bus Open Data vehicle location tracking (AVL)" description="An innovative solution to publishing vehicle location data to the Bus Open Data Service, without an expensive ETM. Available from just £13.50 per month." />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-simple-package" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h2>Bus Open Data Tracker</h2>
                <h3>Vehicle location (AVL) data for BODS from just £13.50/month.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="mobile-hero-simple-package" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h2>Bus Open Data Tracker</h2>
                <h3>Vehicle location (AVL) data for BODS from just £13.50/month.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className='package-page'>

                <div className="package-page__main">

                  <h3>Benefits</h3>
                  <table className="benefits">
                    <tbody>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td><strong>£5,800 saving per vehicle</strong> in year one compared to a leading ETM</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Location data compliance with PSV (Open Data) Regulations</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Supports subcontracted services</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Supports vehicle replacements</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>No driver training required</td>
                      </tr>
                    </tbody>
                  </table>

                  <h3>How it works</h3>
                  <p>Find out how our Bus Open Data Package can help you achieve compliance with the Bus Open Data Service.</p>

                  <div className='package__how-it-works'>

                    <div className={`${this.state.activeTab === 'track' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('track')}}>
                        <span>1. Track your vehicle</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'track' && (<>-</>)}
                          {this.state.activeTab !== 'track' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Vehicle tracking devices that are compliance compatible</p>
                        <ul>
                          <li>Install our recommended vehicle tracking device</li>
                          <li>Various models available included fitted or portable</li>
                          <li>Portable models can be shared between multiple vehicles</li>
                          <li style={{ margin: 0 }}>Devices available at just £5/month and £39 activation fee</li>
                        </ul>
                      </div>
                    </div>

                    <div className={`${this.state.activeTab === 'integrate' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('integrate')}}>
                        <span>2. Integrate your data</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'integrate' && (<>-</>)}
                          {this.state.activeTab !== 'integrate' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Simple and cost-effective compliance</p>
                        <ul>
                          <li>Initial assignment of device to journeys</li>
                          <li>Platform aggregates timetable data with tracking data</li>
                          <li>Data is transformed into a BODS-compliant format (SIRI-VM)</li>
                          <li>Data is sent to BODS instantly</li>
                          <li>No driver input required</li>
                          <li style={{ margin: 0 }}>Platform fee just £7.50/month/device and FREE platform set up</li>
                        </ul>
                      </div>
                      <Link to="/contact/" className="package__how-it-works__next-btn btn">
                        <span>Contact us to find out more</span>
                      </Link>
                    </div>

                  </div>

                  <div className="package__more-info">
                    <h4>Have further questions?</h4>
                    <ul>
                      <li>Complete our enquiry form for more details and pricing</li>
                      <li>You might find your answer in our FAQ section below</li>
                    </ul>
                  </div>

                </div>

                <div className="package-page__pricing">
                  <div className="package-page__pricing-info">
                    <h3 id="pricing">Pricing</h3>

                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Setup</th>
                          <th>Monthly fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tracker</td>
                          <td>£39/device*</td>
                          <td>£6/device</td>
                        </tr>
                        <tr>
                          <td>Platform</td>
                          <td>FREE</td>
                          <td>£7.50/service</td>
                        </tr>
                        <tr>
                          <td><strong>Total</strong></td>
                          <td>£39*</td>
                          <td>£13.50</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>All prices exclude VAT.</p>
                    <p>* Depending on device model chosen. Alternatively a £75 hardware purchase would apply.</p>

                    <p className="highlight no-margin">Complete the enquiry form below for more details and accurate pricing based on your requirements:</p>
                  </div>

                  <form style={{ textAlign: 'left' }} action="/bus-open-data-package-info-requested/" id="register" name="register-bods-tracker" method="POST" className="package-page__pricing-form" data-netlify="true" data-netlify-honeypot="bot-field">

                    <p style={{ display: 'none' }}>
                      <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <div className="package-page__pricing-form__group">
                      <label>Your name *</label>
                      <input placeholder="Your name *" name="name" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Company *</label>
                      <input placeholder="Company *" name="company" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Email *</label>
                      <input placeholder="Email *" name="email" type="email" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Phone *</label>
                      <input placeholder="Phone *" name="phone" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Number of registered services *</label>
                      <input placeholder="Number of registered services *" name="num_services" required className="package-page__pricing-form__input" />
                    </div>
                    <input type="hidden" name="form-name" value="register-bods" />
                    <p>
                      <button className="btn" type="submit">Register interest</button>
                    </p>
                  </form>
                </div>

                <div className="package-page__faq">
                  <h3>FAQ</h3>
                  <div className='package__faq'>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq1' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq1')}}>
                          <span>Q: Can I use my existing tracking device?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq1' && (<>-</>)}
                            {this.state.activeFaq !== 'faq1' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>In order to be compliant with the Bus Open Data Service, vehicle location updates need to be sent every 30 seconds or less.</p>
                          <p>We have found most tracking providers are unable to support this frequency for third-party integrations, which make many of them incompatible for compliance.</p>
                          <p>Our solution is a bespoke integration with a single tracking provider (Pinpointers) that is purpose-built for compliance.</p>
                          <p>The tracking device can be used as a general tracker as well, but doesn't necessarily need to replace your existing tracking if you are happy with your current vendor. It can work alongside it to help fulfil your legal obligations.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq2' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq2')}}>
                          <span>Q: Can you help submit our timetables and fares data too?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq2' && (<>-</>)}
                            {this.state.activeFaq !== 'faq2' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>This package solves the most complicated part of Bus Open Data - vehicle location data (AVL).</p>
                          <p>The Bus Open Data Service offers free tools to submit your own fare and timetable data.</p>
                          <p>If you are struggling with submission of other data, we can help put you in touch with consultants who are able to prepare and submit this data on your behalf.</p>
                        </div>
                      </div>



                    </div>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq3' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq3')}}>
                          <span>Q: Can this package be used standalone?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq3' && (<>-</>)}
                            {this.state.activeFaq !== 'faq3' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Yes! This package is available for use outright, or as an add-on to our Simple and Complete Packages.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq4' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq4')}}>
                          <span>Q: Is this only for use with school services?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq4' && (<>-</>)}
                            {this.state.activeFaq !== 'faq4' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Whilst the ShuttleID system is designed primarily around home-to-school travel this package will also work well on smaller local services.</p>
                          <p>Contact our team to discuss your needs and we will gladly advise you.</p>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
